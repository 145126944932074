import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { SitewideBannerTile } from '../types'
import styles from './Tile.module.scss'

interface TileProps {
  tile: SitewideBannerTile,
}

const contentfulRenderOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <>{children}</>,
  },
}

const Tile = ({ tile }: TileProps) => {
  const { title, callToActionText, callToActionUrl } = tile

  return (
    <p className={styles.container}>
      <a href={callToActionUrl} className={styles.title}>
        {documentToReactComponents(title, contentfulRenderOptions)}
      </a>
      <a href={callToActionUrl} className={styles.callToActionText}>{callToActionText}</a>
    </p>
  )
}

export default Tile
