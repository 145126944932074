import React, {useState, useEffect} from 'react'

const BlockingModal = ({
  bopusModalState: bopusModal = false,
  visible,
  isLoading = false,
  className = '',
  onClose,
  children,
  style: propsStype = {},
  backgroundZIndex = undefined,
}) => {
  const [bopusModalState, setBopusModalState] = useState()

  useEffect(() => {
    setBopusModalState(bopusModalState)
  }, [bopusModal])

  useEffect(() => {
    document.body.classList[visible ? 'add' : 'remove']('has-activeModal')
  }, [visible])

  const showable = (bool) => ({ display: bool ? 'block' : 'none' })

  const visibility = showable(visible)
  const loadingStyle = showable(isLoading)
  const contentStyle = showable(!isLoading)

  const backdropStyle = {
    ...visibility,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: backgroundZIndex
  }

  const style = {
    ...visibility,
    opacity: 1,
    visibility: 'visible',
    top: '733px',
    overflowY: 'auto',
    height: bopusModal? '-webkit-fill-available': undefined,
    zIndex: 9995,
    ...propsStype,
  }

  return (
    <>
      <div
        id="modal"
        className={`modal modal--large open quickViewModal ${className}`}
        data-reveal=""
        data-prevent-quick-search-close=""
        style={style}
        tabIndex="0"
        aria-hidden="false"
      >
        <button className="modal-close" aria-label="Close" onClick={onClose}>
          <span aria-hidden="true">
            <img src="/__statics/images/GrayCloseIcon.svg" alt="close button in gray"/>
          </span>
        </button>
        <div className="modal-content" style={contentStyle}>
          {React.cloneElement(children)}
        </div>
        <div className="loadingOverlay" style={loadingStyle} />
      </div>
      <div
        onClick={onClose}
        className="modal-background"
        style={backdropStyle}
      />
    </>
  )
}

export default BlockingModal
