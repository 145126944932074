import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import BlockingModal from '@/components/Sitewide/BlockingModal'
import { SitewideBannerTile } from '@/components/Sitewide/Banner/types'
import styles from './DetailsModal.module.scss'

interface DetailsModalProps {
  tiles: SitewideBannerTile[],
  visible: boolean,
  onClose: () => void,
}

const contentfulRenderOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <>{children}</>,
  },
}

const DetailsModal = ({ tiles, visible, onClose }: DetailsModalProps) => {
  const renderTiles = () => tiles.map(({ title, disclaimerText }, index) => (
    <div key={JSON.stringify(title)}>
      <p className={styles.title}>{documentToReactComponents(title, contentfulRenderOptions)}</p>
      <p className={styles.description}>{disclaimerText}</p>
      {index !== tiles.length - 1 && <div className={styles.divider} />}
    </div>
  ))

  return (
    <BlockingModal
      style={{ maxWidth: 920 }}
      visible={visible}
      onClose={onClose}
    >
      <div className={styles.container}>
        <p className={styles.header}>Sale & Offers</p>
        {renderTiles()}
      </div>
    </BlockingModal>
  )
}

export default DetailsModal
