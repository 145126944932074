import { useEffect, useState } from 'react'
import Slider from 'react-slick'
import { useWindowScroll } from 'react-use'
import { useIsMobile } from '@/hooks/useDeviceDetect'
import { SitewideBannerTile } from '../types'
import DetailsModal from './DetailsModal'
import Tile from './Tile'
import { PlayIcon, StopIcon } from './icons'
import styles from './BannerV2.module.scss'

interface BannerProps {
  tiles: SitewideBannerTile[]
}

const BannerV2 = ({ tiles }: BannerProps) => {
  const [index, setIndex] = useState(0)
  const [autoplayEnabled, setAutoplayEnabled] = useState(true)
  const [showDetails, setShowDetails] = useState(false)
  const isMobile = useIsMobile()

  const { y } = useWindowScroll()

  useEffect(() => {
    document.querySelector('div#menu.navPages-container')?.classList.add(styles.menu)
    document.querySelector('div#menu.fp_d_sitewide_stickyTopNavigation_fixed')?.classList
      .toggle(styles.menuOnTop, y > (isMobile ? 0 : 80))
  }, [y, isMobile])

  const renderTiles = () => tiles.map((tile) => (
    <Tile key={JSON.stringify(tile.title)} tile={tile} />
  ))

  const renderDots = (dots) => (
    <ul>
      {dots}
      <li className={styles.play}>
        <button
          type="button"
          onClick={() => setAutoplayEnabled((previous) => !previous)}
        >
          {autoplayEnabled ? <StopIcon /> : <PlayIcon />}
        </button>
      </li>
    </ul>
  )

  return (
    <div id="sitewide-banner-v2" className={styles.banner}>
      <Slider
        key={autoplayEnabled}
        initialSlide={index}
        beforeChange={(oldIndex: unknown, newIndex: number) => setIndex(newIndex)}
        className={styles.slider}
        arrows
        circular
        dots={false}
        fade={false}
        infinite
        speed={750}
        pauseOnHover
        slidesToShow={1}
        slidesToScroll={1}
        autoplaySpeed={3500}
        autoplay
        responsive={[{
          breakpoint: 801,
          settings: {
            appendDots: renderDots,
            autoplay: autoplayEnabled,
            draggable: true,
            swipe: true,
            dots: true,
            arrows: false,
          },
        }]}
      >
        {renderTiles()}
      </Slider>
      <DetailsModal
        tiles={tiles}
        visible={showDetails}
        onClose={() => setShowDetails(false)}
      />
    </div>
  )
}

export default BannerV2
