export const PlayIcon = () => (
  <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7 4L0 8L0 0L7 4Z" fill="white" />
  </svg>
)

export const StopIcon = () => (
  <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="2" height="8" fill="white" />
    <rect x="4" width="2" height="8" fill="white" />
  </svg>
)
